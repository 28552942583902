'use client';

import { site } from '@/site';
import { ErrorPage } from '@libs/ui/Page/Error';
import type { Metadata, NextPageContext } from 'next';
import settings from '../../content/main-site/settings/index.json';
import global from '../../content/shared/global/index.json';

export const metadata: Metadata = {
  title: 'Error',
  description: 'OPT Industries Inc | There was an error',
};

export default function Error(props: NextPageContext) {
  return (
    <ErrorPage
      site={site}
      {...props}
      {...props}
      global={global}
      settings={settings}
    />
  );
}
